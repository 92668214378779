import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { Fragment, useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useTranslation } from "react-i18next";
import { addCommas } from "../utils";
import { ClipLoader } from "react-spinners";

const DeleteAlbumModal = ({
  supabase,
  session,
  albums,
  handleClose,
  logout = false,
}) => {
  const { t } = useTranslation();
  const [deleteStatus, setDeleteStatus] = useState("idle");

  const deleteAlbums = async (e) => {
    e.preventDefault();

    let albumsToDelete = albums;

    try {
      const {
        data: { session: supabaseSession },
      } = await supabase.auth.getSession();
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/delete-albums`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${supabaseSession.access_token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            albums: albumsToDelete,
          }),
        }
      );
      const json = await response.json();

      const { error } = json;

      if (error) {
        setDeleteStatus("error");
      } else {
        setDeleteStatus("success");
      }
    } catch (error) {
      setDeleteStatus("error");
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        alignItems: "center",
        width: { xs: "100%", lg: "25rem" },
        flexGrow: 1,
      }}
    >
      {deleteStatus === "idle" ? (
        <Fragment>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "1rem",
              mb: 8,
            }}
          >
            <Typography
              variant="h5"
              fontWeight="bold"
              sx={{
                color: "#FCFCFC",
              }}
            >
              {logout
                ? t("albums.deleteAccount")
                : t("albums.deleteAlbum", {
                    count: addCommas(albums?.length),
                  })}
            </Typography>

            {!logout && (
              <Typography
                variant="body1"
                fontWeight={"bold"}
                color="deepskyblue"
                align="center"
                sx={{
                  width: "100%",
                  padding: { xs: "0 2rem" },
                  mb: 2,
                }}
              >
                {albums &&
                  albums.slice(0, 5).map((album) => "[" + album + "]\n")}
                <br />
                {albums && albums.length > 5 && ` +${albums.length - 5} albums`}
              </Typography>
            )}

            <Typography
              variant="body1"
              color="white"
              align="center"
              sx={{
                width: "80%",
                padding: { xs: "0 2rem" },
                mb: 2,
              }}
            >
              {t("albums.confirm")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "row",
              gap: "0.5rem",
              position: "sticky",
              bottom: "2rem",
              zIndex: 5,
              boxShadow:
                "inset  0px 1.5rem 0px 2rem #121216, 0px 1.5rem 2rem 3rem #121216",
              mt: "auto",
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="error"
              sx={{
                fontWeight: "bold",
              }}
              onClick={(e) =>
                deleteAlbums(e).then(() => {
                  if (logout) {
                    handleClose();
                    session.logout();
                  }
                })
              }
            >
              {t("albums.yes")}
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="info"
              sx={{
                fontWeight: "bold",
                bgcolor: "#dcdcdc",
                "&:hover": {
                  bgcolor: "gray",
                },
              }}
              onClick={() => handleClose()}
            >
              {t("albums.no")}
            </Button>
          </Box>
        </Fragment>
      ) : logout ? (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <ClipLoader color="#fff" loading={true} size={150} />
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            gap: "1rem",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            sx={{
              color: "#FCFCFC",
            }}
          >
            {deleteStatus === "error" ? t("albums.error") : t("albums.success")}
          </Typography>

          {deleteStatus === "error" ? (
            <ErrorIcon color="error" sx={{ fontSize: "5rem" }} />
          ) : (
            <CheckCircleIcon color="success" sx={{ fontSize: "5rem" }} />
          )}

          <Typography
            variant="body1"
            color="white"
            align="center"
            sx={{
              width: "80%",
              padding: { xs: "0 2rem" },
              mb: 2,
            }}
          >
            {deleteStatus === "error"
              ? `${t("albums.wrong")}`
              : t("albums.successText")}
          </Typography>

          <Button
            variant="contained"
            color="info"
            sx={{
              fontWeight: "bold",
              bgcolor: "#dcdcdc",
              "&:hover": {
                bgcolor: "gray",
              },
            }}
            onClick={() => handleClose()}
          >
            {t("albums.close")}
          </Button>
        </Box>
      )}
    </Box>
  );
};
export default DeleteAlbumModal;

import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";

function LandingStats() {
  const { t } = useTranslation();

  const features = [
    { emoji: "🏷️", title: "10M+", description: t("landing.tagged") },
    { emoji: "👥", title: "20K+", description: t("landing.registered") },
    { emoji: "🌍", title: "150+", description: t("landing.countries") },
  ];

  return (
    <Box
      sx={{
        backgroundRepeat: "repeat",
        backgroundPosition: "top, center",
        backgroundSize: "cover",
        borderTop: "1px solid #dcdcdc",
        backgroundColor: "#3535FF",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: "center",
          alignItems: "center",
          gap: { xs: "5rem", md: "3rem" },
          py: 10,
        }}
      >
        {features.map((feature, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "1rem",
              justifyContent: "flex-start",
              alignItems: "center",
              maxWidth: "35rem",
            }}
            key={index}
          >
            <Typography
              variant="h1"
              color="initial"
              fontWeight="bold"
              sx={{ color: "rgba(255,255,255,1)", textAlign: "center" }}
            >
              {feature.title}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "1rem",
                alignItems: "center",
              }}
            >
              <Typography
                variant="h4"
                color="initial"
                fontWeight="bold"
                sx={{ color: "white", textAlign: "center", zIndex: 100 }}
              >
                {feature.emoji}
              </Typography>

              <Typography
                variant="h4"
                color="initial"
                fontWeight="bold"
                sx={{ color: "white", textAlign: "center" }}
              >
                {feature.description}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      ></Box>
    </Box>
  );
}

export default LandingStats;

import React, { useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { NavbarContext } from "../context/NavbarContext";
import { SessionContext } from "../context/SessionContext";
import Chip from "@mui/material/Chip";
import { useTranslation } from "react-i18next";

function LandingExamples() {
  const { t } = useTranslation();
  const navbar = useContext(NavbarContext);
  const session = useContext(SessionContext);

  const examples = [
    {
      file: "product1.jpg",
      title: "Power Strip Surge Protector",
      keywords: ["electronics", "power strip", "surge protector", "white"],
    },
    {
      file: "product2.jpg",
      title: "Women's Ballet Flat",
      keywords: ["shoes", "beige", "ballet", "padded insole"],
    },
    {
      file: "product3.jpg",
      title: "Men's Watch",
      keywords: ["watch", "chronograph", "timepiece", "tachymeter"],
    },
    {
      file: "product4.jpg",
      title: "iPhone 13 Silicone Case",
      keywords: ["phone", "case", "silicone", "pink"],
    },
    {
      file: "product5.jpg",
      title: "Sports Water Bottle",
      keywords: ["water bottle", "sports", "leak-proof", "32 oz"],
    },
    {
      file: "product6.jpg",
      title: "Adjustable Nylon Dog Collar",
      keywords: ["dog", "collar", "nylon", "brown"],
    },
    {
      file: "product7.jpg",
      title: "Red Sports Duffle Bag",
      keywords: ["bag", "duffle", "red", "water-resistant"],
    },
    {
      file: "product8.jpg",
      title: "Gold Hoop Earrings",
      keywords: ["earrings", "hoop", "gold", "jewelry"],
    },
    {
      file: "product9.jpg",
      title: "Black Metal Detector",
      keywords: ["metal detector", "black", "durable", "adventure"],
    },
    {
      file: "product10.jpg",
      title: "Pink Snow Boots",
      keywords: ["boots", "snow", "pink", "waterproof"],
    },
    {
      file: "product11.jpg",
      title: "Blue Combination Padlock",
      keywords: ["lock", "padlock", "combination", "blue"],
    },
    {
      file: "product12.jpg",
      title: "Bluetooth Wireless Headphones",
      keywords: ["headphones", "wireless", "bluetooth", "black"],
    },
    {
      file: "product13.jpg",
      title: "Disposable Rain Poncho",
      keywords: ["poncho", "rain", "disposable", "emergency"],
    },
    {
      file: "product14.jpg",
      title: "Makeup Remover Pads",
      keywords: ["makeup", "remover", "pads", "hypoallergenic"],
    },
    {
      file: "product15.jpg",
      title: "Women's Winter Puffer Coat",
      keywords: ["coat", "puffer", "winter", "jacket"],
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "white",
        overflowX: "hidden",
      }}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          textAlign: "center",
          py: 5,
          borderTop: "1px solid #dcdcdc",
          width: "95%",
        }}
      >
        🚀 {t("landing.accelerate")}
      </Typography>
      <Typography
        variant="body1"
        sx={{
          color: "#000",
          textAlign: "center",
          px: { xs: "10%", md: "30%" },
        }}
      >
        ⚡<strong> {t("landing.section1Sentence1")}</strong>{" "}
        {t("landing.section1Sentence2")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: 5,
          py: 5,
          overflowX: "hidden",
          width: `${15 * examples.length + 10 * (examples.length - 1)}rem`, // Calcula el ancho de la sección
          animation: `marquee ${examples.length / 0.5 + 5}s linear infinite`, // Agrega tiempo
          animationTimingFunction: "linear", // Ajusta la propiedad de tiempo de animación
          "@keyframes marquee": {
            "0%": {
              transform: "translateX(0%)",
            },
            "100%": {
              transform: `translateX(-50.35%)`, // Calcula la cantidad de porcentaje para mover la sección
            },
          },
        }}
      >
        {/* Duplica las imágenes para hacer la animación infinita */}
        {[...examples, ...examples].map((example, index) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "10rem",
              gap: 2,
              transition: "all 100ms, borderRadius 0ms",
              "&:hover": {
                transition: "all 100ms",
                borderRadius: "2rem",
                cursor: "pointer",
                zIndex: 10,
              },
            }}
            key={index}
          >
            <Box
              sx={{
                position: "relative",
                backgroundImage: `url(/assets/landing/examples/${example.file})`,
                backgroundSize: "contain",
                backgroundPosition: "center",
                backgroundRepeat: "no-repeat",
                backgroundColor: "white",
                width: "10rem",
                aspectRatio: "4 / 5",
                borderRadius: "2rem",
                outline: "5px #dcdcdc solid",
                py: 2,
                boxSizing: "border-box",
                transition: "all 100ms, borderRadius 0ms",
                "&:hover": {
                  outline: "5px #1a72f5 solid",
                  boxShadow:
                    "0px 0px 20px #1a72f5, 0px 20px 20px 0px rgba(0, 0, 0, 0.8), 0px 0px 60px 70px rgba(255, 255, 255, 1)",
                  transition: "all 100ms",
                  borderRadius: "2rem",
                  cursor: "pointer",
                  zIndex: 10,
                  mb: 2,
                  transform: "scale(1.1)",
                },
                "&:active": {
                  transform: "scale(0.9)",
                  boxShadow:
                    "0px 0px 20px #1a72f5, 0px 0px 20px 0px rgba(0, 0, 0, 0.8), 0px 0px 60px 70px rgba(255, 255, 255, 1)",
                },
              }}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  height: "100%",
                  transition: "all ease-in-out 200ms",
                  opacity: 0,
                  "&:hover": {
                    opacity: 1,
                    height: "130%",
                  },
                }}
              >
                {[...example.keywords.slice(0, 5), "+36 tags"].map(
                  (keyword, i) => (
                    <Box
                      sx={{
                        position: "absolute",
                        top: `${-8 + (50 * i) / 3.5}%`,
                        right: `90%`,
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "flex-end",
                        borderRadius: "1rem",
                        overflow: "hidden",
                        boxSizing: "border-box",
                      }}
                      key={i}
                    >
                      <Chip
                        label={keyword}
                        size="small"
                        key={i}
                        style={{
                          margin: `0.2rem`,
                          backgroundColor: `black`,
                          color: "#fff",
                          cursor: "pointer",
                          fontWeight: "bold",
                        }}
                        sx={{
                          padding: ".2rem",
                          transition: "all 80ms ease-out",
                        }}
                        onDelete={() => {
                          if (session?.stashedUser) {
                            session?.loadUserFromStash();
                          } else {
                            navbar.setSelectedNavItem("login");
                            navbar.setOpenNavModal(true);
                          }
                        }}
                      />
                    </Box>
                  )
                )}
              </Box>
            </Box>
            <Typography
              variant="subtitle2"
              sx={{
                textAlign: "center",
                fontWeight: "bold",
                color: "black",
                zIndex: 90,
                userSelect: "none",
                pointerEvents: "none",
                width: "120%",
                alignSelf: "center",
              }}
            >
              {example.title}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
}

export default LandingExamples;

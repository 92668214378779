import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Flag from "react-world-flags";
import { styled } from "@mui/material/styles";

const LightRedSelect = styled(Select)({
  backgroundColor: "#1a72f5",
  color: "white",
  "&:before": {
    borderColor: "#1a72f5",
  },
  "&:after": {
    borderColor: "#1a72f5",
  },
  "& .MuiSelect-select": {
    display: "flex",
    alignItems: "center",
  },
  "& .MuiSelect-icon": {
    color: "white",
  },
  minWidth: 120,
});

const LanguageSelector = () => {
  const { i18n } = useTranslation();

  const changeLanguage = (event) => {
    i18n.changeLanguage(event.target.value);
    localStorage.setItem("i18nextLng", event.target.value);
  };

  const languages = [
    { code: "en", name: "English", countryCode: "gb" },
    { code: "es", name: "Español", countryCode: "es" },
    { code: "pt", name: "Português", countryCode: "pt" },
    { code: "de", name: "Deutsch", countryCode: "de" },
    { code: "fr", name: "Français", countryCode: "fr" },
    { code: "it", name: "Italiano", countryCode: "it" },
    { code: "pl", name: "Polskie", countryCode: "pl" },
    { code: "ru", name: "русский", countryCode: "ru" },
    { code: "uk", name: "українська", countryCode: "ua" },
    { code: "id", name: "Indonesia", countryCode: "id" },
    { code: "hi", name: "हिन्दी", countryCode: "in" },
    { code: "zh", name: "中文", countryCode: "cn" },
    { code: "ko", name: "한국어", countryCode: "kr" },
    { code: "ja", name: "日本語", countryCode: "jp" },
    { code: "ar", name: "عربى", countryCode: "sa" },
    { code: "he", name: "עִברִית", countryCode: "il" },
  ];

  const getLanguageCode = (code) => {
    const strippedCode = code?.split("-")?.[0];
    const language = languages.find((lang) => lang.code === strippedCode);
    return language ? language.code : "en";
  };

  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", checkIsMobile);
    checkIsMobile();
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  const containerStyle = isMobile
    ? {
        position: "fixed",
        bottom: 15,
        left: 15,
        zIndex: 1000,
      }
    : {
        position: "fixed",
        bottom: 20,
        right: 100,
        zIndex: 1000,
      };

  return (
    <>
      <FormControl style={containerStyle}>
        <LightRedSelect
          id="language-selector"
          name="language-selector"
          value={getLanguageCode(i18n.language)}
          onChange={changeLanguage}
          displayEmpty
          inputProps={{ "aria-label": "Without label" }}
          defaultValue={getLanguageCode(localStorage.getItem("i18nextLng"))}
          MenuProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "center",
            },
            transformOrigin: {
              vertical: "bottom",
              horizontal: "center",
            },
            PaperProps: {
              style: {
                marginTop: "-10px",
                maxHeight: "36rem",
              },
            },
          }}
        >
          {languages.map((language) => (
            <MenuItem key={language.code} value={language.code}>
              <Flag
                code={language.countryCode}
                height={isMobile ? "12" : "16"}
                style={{ marginRight: "10px" }}
              />
              {" " + language.name}
            </MenuItem>
          ))}
        </LightRedSelect>
      </FormControl>
    </>
  );
};

export default LanguageSelector;

import React, { useContext } from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import LandingLayout from "../Layout/LandingLayout";
import { useTranslation } from "react-i18next";

function LandingUse() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);

  const features = [
    {
      video: "upload",
      title: "📸 " + t("landing.uploadPhotos"),
      description: t("landing.uploadDescription"),
      reverse: false,
      mdReverse: false,
      styles: {
        pt: 5,
        pb: 5,
        backgroundColor: "#fff",
        gap: { xs: "2rem", md: "6rem" },
      },
    },
    {
      video: "edit",
      title: "🤖 " + t("landing.edit"),
      description: t("landing.editDescription"),
      reverse: true,
      mdReverse: false,
      styles: {
        pt: 5,
        pb: 5,
        backgroundColor: "#fff",
        gap: { xs: "2rem", md: "6rem" },
        borderTop: "1px solid #dcdcdc",
      },
    },
    {
      video: "export",
      title: "💾 " + t("landing.export"),
      description: t("landing.exportDescription"),
      reverse: false,
      mdReverse: false,
      styles: {
        pt: 5,
        pb: 5,
        backgroundColor: "#fff",
        gap: { xs: "2rem", md: "6rem" },
        borderTop: "1px solid #dcdcdc",
      },
    },
  ];

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
      }}
    >
      <Typography
        variant="h3"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          backgroundColor: "white",
          textAlign: "center",
          py: 5,
          px: 2,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        {t("landing.how")}
      </Typography>
      {features.map((feature, index) => (
        <LandingLayout
          styles={feature.styles}
          key={index}
          reverse={feature.reverse}
          mdReverse={feature.mdReverse}
          firstContent={
            window.innerWidth > 768 && (
              <video
                src={`/assets/landing/${feature.video}.webm`}
                poster={"/assets/poster.png"}
                preload="auto"
                muted
                autoPlay
                loop
                controls={false}
                style={{
                  maxWidth: "20rem",
                  width: "20rem",
                  borderRadius: "2rem",
                  outline: "5px solid #dcdcdc",
                }}
              />
            )
          }
          secondfContent={
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "flex-start",
                maxWidth: "20rem",
              }}
            >
              <Typography
                variant="h4"
                color="initial"
                fontWeight="bold"
                textAlign={"left"}
                sx={{ color: "#000", textAlign: "left" }}
              >
                {feature.title}
              </Typography>

              <Typography
                variant="body1"
                color="initial"
                sx={{ color: "#000", align: "left" }}
              >
                {feature.description}
              </Typography>
            </Box>
          }
        />
      ))}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            backgroundColor: "black",
            color: "white",
            maxWidth: "20rem",
            fontWeight: "bold",
            my: "5rem",
            fontSize: "1.5rem !important",
          }}
          className="gradient-bg2"
          onClick={() => {
            if (session?.stashedUser) {
              session?.loadUserFromStash();
            } else {
              navbar.setSelectedNavItem("login");
              navbar.setOpenNavModal(true);
            }
          }}
          startIcon={!session?.user && <AutoFixHighIcon />}
        >
          {t("landing.tryNow")}
        </Button>
      </Box>
    </Box>
  );
}

export default LandingUse;

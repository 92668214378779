import React, { useEffect, useContext, useState } from "react";
import Button from "@mui/material/Button";
import { useParams, useLocation } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { SessionContext } from "../context/SessionContext";
import { NavbarContext } from "../context/NavbarContext";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Help from "../components/Help";
import { useTranslation } from "react-i18next";

function LandingFaqs() {
  const { t } = useTranslation();
  const session = useContext(SessionContext);
  const navbar = useContext(NavbarContext);
  const location = useLocation();
  const { section } = useParams();
  const [isPageLoaded, setIsPageLoaded] = useState(false);

  useEffect(() => {
    const handlePageLoad = () => {
      setIsPageLoaded(true);
    };

    if (document.readyState === "complete") {
      setIsPageLoaded(true);
    } else {
      window.addEventListener("load", handlePageLoad);
    }

    return () => {
      window.removeEventListener("load", handlePageLoad);
    };
  }, []);

  const handleScrollToHelp = () => {
    const element = document.getElementById("faqs");
    element.scrollIntoView();
    window.scrollBy(0, -100);
  };

  useEffect(() => {
    if (isPageLoaded && location.state?.scrollToHelp) {
      handleScrollToHelp();
    }
  }, [location.state, isPageLoaded]);

  useEffect(() => {
    if (isPageLoaded && section === "faq") {
      handleScrollToHelp();
    }
  }, [section, isPageLoaded]);

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        width: "100%",
      }}
      id={"faqs"}
    >
      <Typography
        variant="h2"
        fontWeight={"bold"}
        sx={{
          color: "#000",
          backgroundColor: "white",
          textAlign: "center",
          py: 10,
          px: 2,
          borderTop: "1px solid #dcdcdc",
        }}
      >
        FAQ
      </Typography>

      <Box
        sx={{
          width: "100%",
        }}
      >
        <Help />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Button
          size="large"
          variant="contained"
          color="secondary"
          fullWidth
          sx={{
            backgroundColor: "black",
            color: "white",
            maxWidth: "20rem",
            fontWeight: "bold",
            my: "5rem",
            fontSize: "1.5rem !important",
          }}
          className="gradient-bg2"
          onClick={() => {
            if (session?.stashedUser) {
              session?.loadUserFromStash();
            } else {
              navbar.setSelectedNavItem("login");
              navbar.setOpenNavModal(true);
            }
          }}
          startIcon={!session?.user && <AutoFixHighIcon />}
        >
          {t("landing.tryNow")}
        </Button>
      </Box>
    </Box>
  );
}

export default LandingFaqs;

import React from "react";
import { useTranslation } from "react-i18next";
import Continue from "./Continue";

function Thanks() {
  const { t } = useTranslation();

  return (
    <Continue
      primaryText={t("thanks.header")}
      buttonText={t("thanks.button")}
      timeout={300}
    />
  );
}

export default Thanks;

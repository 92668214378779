export const getFormat = (formatName, selectedValues) => {
  const formats = {
    default: {
      fields: {
        file: { value: "name" },
        title: { value: "title" },
        description: { value: "description" },
        keywords: { value: "keywords" },
      },
      options: {
        semicolon: false,
      },
    },
    shopify: {
      fields: {
        Handle: { value: "name" },
        Title: { value: "title" },
        "Body (HTML)": { value: "description" },
        Vendor: { value: "" },
        "Product Category": { value: "" },
        Type: { value: "" },
        Tags: { value: "keywords" },
        Published: { value: "" },
        "Option1 Name": { value: "" },
        "Option1 Value": { value: "" },
        "Option2 Name": { value: "" },
        "Option2 Value": { value: "" },
        "Option3 Name": { value: "" },
        "Option3 Value": { value: "" },
        "Variant SKU": { value: "" },
        "Variant Grams": { value: "" },
        "Variant Inventory Tracker": { value: "" },
        "Variant Inventory Qty": { value: "" },
        "Variant Inventory Policy": { value: "" },
        "Variant Fulfillment Service": { value: "" },
        "Variant Price": { value: "" },
        "Variant Compare At Price": { value: "" },
        "Variant Requires Shipping": { value: "" },
        "Variant Taxable": { value: "" },
        "Variant Barcode": { value: "" },
        "Image Src": { value: "" },
        "Image Position": { value: "" },
        "Image Alt Text": { value: "" },
        "Gift Card": { value: "" },
        "SEO Title": { value: "title" },
        "SEO Description": { value: "description" },
        "Google Shopping / Google Product Category": { value: "" },
        "Google Shopping / Gender": { value: "" },
        "Google Shopping / Age Group": { value: "" },
        "Google Shopping / MPN": { value: "" },
        "Google Shopping / AdWords Grouping": { value: "" },
        "Google Shopping / AdWords Labels": { value: "" },
        "Google Shopping / Condition": { value: "" },
        "Google Shopping / Custom Product": { value: "" },
        "Google Shopping / Custom Label 0": { value: "" },
        "Google Shopping / Custom Label 1": { value: "" },
        "Google Shopping / Custom Label 2": { value: "" },
        "Google Shopping / Custom Label 3": { value: "" },
        "Google Shopping / Custom Label 4": { value: "" },
        "Variant Image": { value: "" },
        "Variant Weight Unit": { value: "" },
        "Variant Tax Code": { value: "" },
        "Cost per item": { value: "" },
        "Price / International": { value: "" },
        "Compare At Price / International": { value: "" },
        Status: { value: "" },
      },
      options: {
        semicolon: false,
      },
    },
    bigcommerce: {
      fields: {
        Item: { value: "" },
        ID: { value: "name" },
        Name: { value: "title" },
        Type: { value: "" },
        SKU: { value: "" },
        Options: { value: "" },
        "Inventory Tracking": { value: "" },
        "Current Stock": { value: "" },
        "Low Stock": { value: "" },
        Price: { value: "" },
        "Cost Price": { value: "" },
        "Retail Price": { value: "" },
        "Sale Price": { value: "" },
        "Brand ID": { value: "" },
        Channels: { value: "" },
        Categories: { value: "" },
        Description: { value: "description" },
        "Custom Fields": { value: "" },
        "Page Title": { value: "title" },
        "Product URL": { value: "" },
        "Meta Description": { value: "description" },
        "Search Keywords": { value: "keywords" },
        "Meta Keywords": { value: "keywords" },
        "Bin Picking Number": { value: "" },
        "UPC/EAN": { value: "" },
        "Global Trade Number": { value: "" },
        "Manufacturer Part Number": { value: "" },
        "Free Shipping": { value: "" },
        "Fixed Shipping Cost": { value: "" },
        Weight: { value: "" },
        Width: { value: "" },
        Height: { value: "" },
        Depth: { value: "" },
        "Is Visible": { value: "" },
        "Is Featured": { value: "" },
        Warranty: { value: "" },
        "Tax Class": { value: "" },
        "Product Condition": { value: "" },
        "Show Product Condition": { value: "" },
        "Sort Order": { value: "" },
        "Variant Image URL": { value: "" },
        "Internal Image URL (Export)": { value: "" },
        "Image URL (Import)": { value: "" },
        "Image Description": { value: "" },
        "Image is Thumbnail": { value: "" },
        "Image Sort Order": { value: "" },
        "YouTube ID": { value: "" },
        "Video Title": { value: "" },
        "Video Description": { value: "" },
        "Video Sort Order": { value: "" },
      },
      options: {
        semicolon: false,
      },
    },
    woocommerce: {
      fields: {
        ID: { value: "name" },
        Type: { value: "" },
        SKU: { value: "" },
        Name: { value: "title" },
        Published: { value: "" },
        "Is featured?": { value: "" },
        "Visibility in catalog": { value: "" },
        "Short description": { value: "description" },
        Description: { value: "description" },
        "Date sale price starts": { value: "" },
        "Date sale price ends": { value: "" },
        "Tax status": { value: "" },
        "Tax class": { value: "" },
        "In stock?": { value: "" },
        Stock: { value: "" },
        "Backorders allowed?": { value: "" },
        "Sold individually?": { value: "" },
        "Weight (lbs)": { value: "" },
        "Length (in)": { value: "" },
        "Width (in)": { value: "" },
        "Height (in)": { value: "" },
        "Allow customer reviews?": { value: "" },
        "Purchase note": { value: "" },
        "Sale price": { value: "" },
        "Regular price": { value: "" },
        Categories: { value: "keywords" },
        Tags: { value: "keywords" },
        "Shipping class": { value: "" },
        Images: { value: "" },
        "Download limit": { value: "" },
        "Download expiry days": { value: "" },
        Parent: { value: "" },
        "Grouped products": { value: "" },
        Upsells: { value: "" },
        "Cross-sells": { value: "" },
        "External URL": { value: "" },
        "Button text": { value: "" },
        Position: { value: "" },
        "Attribute 1 name": { value: "" },
        "Attribute 1 value(s)": { value: "" },
        "Attribute 1 visible": { value: "" },
        "Attribute 1 global": { value: "" },
        "Attribute 2 name": { value: "" },
        "Attribute 2 value(s)": { value: "" },
        "Attribute 2 visible": { value: "" },
        "Attribute 2 global": { value: "" },
        "Meta: _wpcom_is_markdown": { value: "" },
        "Download 1 name": { value: "" },
        "Download 1 URL": { value: "" },
        "Download 2 name": { value: "" },
        "Download 2 URL": { value: "" },
      },
      options: {
        semicolon: false,
      },
    },
    wix: {
      fields: {
        handleId: { value: "name" },
        fieldType: { value: "" },
        name: { value: "title" },
        description: { value: "description" },
        productImageUrl: { value: "" },
        collection: { value: "" },
        sku: { value: "" },
        ribbon: { value: "" },
        price: { value: "" },
        surcharge: { value: "" },
        visible: { value: "" },
        discountMode: { value: "" },
        discountValue: { value: "" },
        inventory: { value: "" },
        weight: { value: "" },
        cost: { value: "" },
        productOptionName1: { value: "" },
        productOptionType1: { value: "" },
        productOptionDescription1: { value: "" },
        productOptionName2: { value: "" },
        productOptionType2: { value: "" },
        productOptionDescription2: { value: "" },
        productOptionName3: { value: "" },
        productOptionType3: { value: "" },
        productOptionDescription3: { value: "" },
        productOptionName4: { value: "" },
        productOptionType4: { value: "" },
        productOptionDescription4: { value: "" },
        productOptionName5: { value: "" },
        productOptionType5: { value: "" },
        productOptionDescription5: { value: "" },
        productOptionName6: { value: "" },
        productOptionType6: { value: "" },
        productOptionDescription6: { value: "" },
        additionalInfoTitle1: { value: "" },
        additionalInfoDescription1: { value: "" },
        additionalInfoTitle2: { value: "" },
        additionalInfoDescription2: { value: "" },
        additionalInfoTitle3: { value: "" },
        additionalInfoDescription3: { value: "" },
        additionalInfoTitle4: { value: "" },
        additionalInfoDescription4: { value: "" },
        additionalInfoTitle5: { value: "" },
        additionalInfoDescription5: { value: "" },
        additionalInfoTitle6: { value: "" },
        additionalInfoDescription6: { value: "" },
        customTextField1: { value: "" },
        customTextCharLimit1: { value: "" },
        customTextMandatory1: { value: "" },
        brand: { value: "" },
      },
      options: {
        semicolon: false,
      },
    },
    squarespace: {
      fields: {
        "Product ID [Non Editable]": { value: "name" },
        "Variant ID [Non Editable]": { value: "" },
        "Product Type [Non Editable]": { value: "" },
        "Product Page": { value: "" },
        "Product URL": { value: "" },
        Title: { value: "title" },
        Description: { value: "description" },
        SKU: { value: "" },
        "Option Name 1": { value: "" },
        "Option Value 1": { value: "" },
        "Option Name 2": { value: "" },
        "Option Value 2": { value: "" },
        "Option Name 3": { value: "" },
        "Option Value 3": { value: "" },
        Price: { value: "" },
        "Sale Price": { value: "" },
        "On Sale": { value: "" },
        Stock: { value: "" },
        Categories: { value: "keywords" },
        Tags: { value: "keywords" },
        Weight: { value: "" },
        Length: { value: "" },
        Width: { value: "" },
        Height: { value: "" },
        Visible: { value: "" },
        "Hosted Image URLs": { value: "" },
      },
      options: {
        semicolon: false,
      },
    },
  };

  return formats?.[formatName];
};
